import React, { Component } from "react";
import Layout from "../layout";
import Hero from "../sections/hero";
import { PhoneIcon, MailIcon } from '@heroicons/react/outline';
import { Link } from 'gatsby';
import FeaturedImage from '../images/featured-contact.jpg';

class ContactPage extends Component {
  render() {
    return (
      <Layout>
        <Hero image={FeaturedImage}>
          <div className="sm:text-center lg:text-left">
            <h1 className="text-4xl tracking-tight font-extrabold text-slate-900 sm:text-5xl md:text-6xl">
              <span className="block xl:inline">We're tech partner of</span>{' '}
              <span className="block text-blue-600 xl:inline">Your Innovations</span>
            </h1>
            <p className="mt-3 text-base text-slate-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
              Vinkas is a provider of IT consulting and software development services. We have helped organizations and companies improve business performance &amp; enhance their competitiveness.
            </p>
            <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
              <div className="rounded-md shadow">
                <a
                  href="#"
                  className="btn-lg text-white bg-blue-600 hover:bg-blue-700"
                >
                  <PhoneIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                  Call us
                </a>
              </div>
              <div className="mt-3 sm:mt-0 sm:ml-3">
                <Link
                  to="/contact"
                  className="btn-lg text-blue-700 bg-blue-100 hover:bg-blue-200"
                >
                  <MailIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                  Send message
                </Link>
              </div>
            </div>
          </div>
        </Hero>
      </Layout>
    )
  }
}

export default ContactPage;
